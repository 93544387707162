import React, { useState, useEffect, useRef } from "react";
import { Cards } from "../../../data/MarketData";
import { useSpring, animated } from "@react-spring/web";
import "./market.css";
import Card from "./Card"; // Import the Card component

const Market = ({ onQuantityChange }) => {
    const cards = Cards();
    const containerRef = useRef(null); // Reference to the container element
    const [containerHeight, setContainerHeight] = useState('auto');

    // Spring configuration for vertical scrolling
    const [props, api] = useSpring(() => ({
        transform: 'translateY(0px)',
        height: `130%`,
        config: { tension: 170, friction: 26 }
    }));

    const handleScroll = (event) => {
        // Handle vertical scroll event
        const scrollTop = event.target.scrollTop;
        api.start({ transform: `translateY(-${scrollTop}px)` });
    };

    useEffect(() => {
        if (containerRef.current) {
            // Set container height based on its scrollHeight
            setContainerHeight(containerRef.current.scrollHeight);
        }
    }, [cards]); // Update height when cards change

    return (
        <div
            className="market-container"
            onScroll={handleScroll}
            ref={containerRef}
            style={{ height: `130%` }}
        >
            <animated.div className="market-content" style={props }>
                {cards.map(card => (
                    <Card
                        key={card.id}
                        img={card.img}
                        title={card.title}
                        description={card.description}
                        price={card.price}
                        energyConsuption={card.energyConsumption}
                        production={card.production}
                        onQuantityChange={onQuantityChange}
                    />
                ))}
            </animated.div>
        </div>
    );
};

export default Market;