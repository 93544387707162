import React, {useState, useEffect} from "react";
import axios from "axios";
import {useAuth} from "../context/AuthContext";
import {useTon} from "../context/TonContext";
import Nav from "./HomeNav";
import Market from "./nav_pages/market/Market";
import "./body.css";
import Farm from "./nav_pages/farm/Farm";
import Rewards from "./nav_pages/rewards/Rewards";
import Friends from "./nav_pages/friends/Friends";

const HomeBody = ({energy}) => {
    const { authToken } = useAuth();
    const {wallet, tonConnectUi} = useTon();
    const [selectedTab, setSelectedTab] = useState('market');
    const [activeTab, setActiveTab] = useState('active');
    const [activeTabRew, setActiveTabRew] = useState('withdrawal');
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const my_wallet = process.env.REACT_APP_WALLET;
    const host = process.env.REACT_APP_HOST;

    // useEffect(() => {
    //     const pendingTransaction = localStorage.getItem('pendingTransaction');
    //     if (pendingTransaction) {
    //         const {boc, cart, totalAmount, walletAddress} = JSON.parse(pendingTransaction);
    //         axios.post(`${host}/card/add`,
    //             {
    //                 tx: boc,
    //                 cardData: cart,
    //                 totalAmount,
    //                 wallet: walletAddress
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${authToken}`,
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         ).then(response => {
    //             if (response.status === 200) {
    //                 localStorage.removeItem('pendingTransaction');
    //                 setCart([]);
    //                 setTotal(0);
    //             }
    //         });
    //     }
    // }, [authToken, host]);

    const handleNavClick = (tab) => {
        setSelectedTab(tab);
        setCart([]);
    };

    const handleQuantityChange = (title, price, quantity) => {
        setCart(prevCart => {
            const existingItemIndex = prevCart.findIndex(item => item.title === title);
            if (existingItemIndex >= 0) {
                if (quantity === 0) {
                    return prevCart.filter((_, index) => index !== existingItemIndex);
                }
                const updatedCart = [...prevCart];
                updatedCart[existingItemIndex] = {title, price, quantity};
                return updatedCart;
            }
            if (quantity > 0) {
                return [...prevCart, {title, price, quantity}];
            }
            return prevCart;
        });
    };

    useEffect(() => {
        const newTotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
        setTotal(newTotal);
    }, [cart]);

    const handleBuyClick = async () => {
        if (!wallet) {
            return tonConnectUi?.openModal();
        } else {
            const totalTon = total * 1_000_000_000;

            try {
                const transaction = {
                    validUntil: Math.floor(Date.now() / 1000) + 600,
                    messages: [
                        {
                            address: `${my_wallet}`,
                            amount: totalTon.toString(),
                        },
                    ],
                };

                const result = await tonConnectUi.sendTransaction(transaction);

                if (!result || !result.boc) {
                    return;
                }

                // const pendingTransaction = {
                //     boc: result.boc,
                //     cart,
                //     totalAmount: total,
                //     walletAddress: wallet.account.address
                // };

                // localStorage.setItem('pendingTransaction', JSON.stringify(pendingTransaction));

                setTimeout(async () => {
                    try {

                        const response = await axios.post(`${host}/card/add`,
                            {
                                tx: result.boc,
                                cardData: cart,
                                totalAmount: total,
                                wallet: wallet.account.address
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                    "Content-Type": "application/json",
                                },
                            }
                        );

                        if (response.status === 200) {
                            // localStorage.removeItem('pendingTransaction');
                            setCart([]);
                            setTotal(0);
                        }
                    } catch (serverError) {
                    }
                }, 5000);

            } catch (error) {
            }
        }
    };

    return (
        <div className="home-container">
            <Nav onNavClick={handleNavClick} activeTab={selectedTab}/>
            <div className="content-container">
                {selectedTab === 'market' && <Market onQuantityChange={handleQuantityChange}/>}
                {selectedTab === 'farm' && <Farm activeTab={activeTab} energy={energy}/>}
                {selectedTab === 'friends' && <Friends/>}
                {selectedTab === 'rewards' && <Rewards activeTabRew={activeTabRew}/>}
            </div>
            {selectedTab === 'market' && cart.length > 0 && (
                <div className="checkout-container">
                    <h3>Total: {total.toFixed(2) + ' Ton'}</h3>
                    <button className="checkout-button" onClick={handleBuyClick}>Buy</button>
                </div>
            )}
            {selectedTab === 'farm' && (
                <div className="checkout-container">
                    <div className="tabs">
                        <button
                            className={activeTab === 'active' ? 'active' : ''}
                            onClick={() => setActiveTab('active')}
                        >
                            Active
                        </button>
                        <button
                            className={activeTab === 'inactive' ? 'active' : ''}
                            onClick={() => setActiveTab('inactive')}
                        >
                            Inactive
                        </button>
                    </div>
                </div>
            )}
            {selectedTab === 'rewards' &&
                <div className="checkout-container">
                    <div className={"tabs"}>
                        <button
                            className={activeTabRew === 'withdrawal' ? 'active' : ''}
                            onClick={() => setActiveTabRew('withdrawal')}
                        >
                            Withdrawal
                        </button>
                        <button
                            className={activeTabRew === 'history' ? 'active' : ''}
                            onClick={() => setActiveTabRew('history')}
                        >
                            History
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default HomeBody;