import React, {useState, useEffect} from "react";
import axios from "axios";
import "./rewards.css";
import {useAuth} from "../../../context/AuthContext";
import Transactions from "../transactions/Transactions";
import {useTon} from "../../../context/TonContext";

const Rewards = ({activeTabRew}) => {
    const {authToken} = useAuth();
    const {wallet, tonConnectUi} = useTon();
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false); // состояние для модалки
    const host = process.env.REACT_APP_HOST;

    // Функция для получения баланса
    const fetchBalance = async () => {
        try {
            const response = await axios.get(`${host}/user/balance`, {
                headers: {Authorization: `Bearer ${authToken}`}
            });
            setBalance(response.data);
        } catch (error) {
            console.error("Error fetching balance:", error);
        }
    };

    useEffect(() => {
        fetchBalance();

        const interval = setInterval(fetchBalance, 60000);
        return () => clearInterval(interval);
    }, [host]);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleWithdraw = async () => {
        const amountToWithdraw = parseFloat(amount);
        setError("");
        if (isNaN(amountToWithdraw) || amountToWithdraw > balance) {
            setError("Minimum withdrawal amount 1 Ton.");
            return;
        }

        if (!wallet) {
            return tonConnectUi?.openModal();
        }

        if (balance === 0) {
            setError("There are not enough funds on the balance.");
            return;
        }

        try {
            const response = await axios.post(`${host}/transaction/withdrawal`, {
                amount: amountToWithdraw
            }, {
                headers: {Authorization: `Bearer ${authToken}`}
            });

            if (response.status === 200) {
                setAmount("");
                setError("");
                fetchBalance();
                setShowSuccessModal(true);
            }

            await axios.post(`${host}/user/wallet`, {
                wallet:  wallet.account.address
            },
                {headers: {Authorization: `Bearer ${authToken}`}
                });

        } catch (error) {
            setError("Error during withdrawal. Please try again later.");
        }
    };

    const closeModal = () => {
        setShowSuccessModal(false);
    };

    if (activeTabRew !== 'withdrawal') {
        return (
            <div className={"rewards-container"}>
                <Transactions/>
            </div>
        );
    }

    return (
        <div className="rewards-container">
            <div className={"balance"}>
                <p>Balance: {balance.toFixed(3)} TON</p>
            </div>
            <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={handleAmountChange}
                className="amount-input"
            />
            {error && <div className="error-message">{error}</div>}
            <button onClick={handleWithdraw} className="withdraw-button">
                Withdraw
            </button>

            {showSuccessModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Success!</p>
                        <p>The funds will be credited to your address within 24 hours.</p>
                        <p>You can check the transaction status in the History tab.</p>
                        <p>If the funds do not arrive, you can contact us at: <strong>payment@tonfarm.tech</strong>
                        </p>
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Rewards;