import React, {createContext, useContext} from 'react';
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";

const TonContext = createContext(null);

export const TonProvider = ({children}) => {
    const [tonConnectUi] = useTonConnectUI();
    const wallet = useTonWallet();


    return (
        <TonContext.Provider value={{tonConnectUi, wallet}}>
            {children}
        </TonContext.Provider>
    );
};

export const useTon = () => {
    const context = useContext(TonContext);
    if (!context) {
        throw new Error('useTon must be used within a TonProvider');
    }
    return context;
};