import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from "../../../context/AuthContext";
import "./energy.css";

const UserEnergy = ({ onEnergyUpdate }) => {
    const [userEnergy, setUserEnergy] = useState(0);
    const { authToken } = useAuth();
    const host = process.env.REACT_APP_HOST

    const fetchUserEnergy = useCallback(async () => {
        try {
            const response = await axios.get(`${host}/user/energy`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });
            setUserEnergy(response.data);
            onEnergyUpdate(response.data); // Передаем обновленную энергию родителю
        } catch (error) {
        }
    }, [authToken, onEnergyUpdate]);

    useEffect(() => {
        fetchUserEnergy();
        const interval = setInterval(fetchUserEnergy, 60000);
        return () => clearInterval(interval);
    }, [fetchUserEnergy]);

    return (
        <div className="user-energy">
            <p>Energy: {userEnergy.toFixed(2)}</p>
        </div>
    );
};

export default UserEnergy;