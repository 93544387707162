import React from 'react';
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";

export const TonConnectProvider = ({ children }) => {
    return (
        <TonConnectUIProvider
            manifestUrl="https://tonfarm.tech/tonconnect-manifest.json"
            uiPreferences={{ theme: THEME.DARK }}
            walletsListConfiguration={{
                includeWallets: [
                    {
                        appName: "tonwallet",
                        name: "TON Wallet",
                        imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
                        aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
                        universalLink: "https://wallet.ton.org/ton-connect",
                        jsBridgeKey: "tonwallet",
                        bridgeUrl: "https://bridge.tonapi.io/bridge",
                        platforms: ["chrome", "android"]
                    },
                    {
                        appName: "nicegramWallet",
                        name: "Nicegram Wallet",
                        imageUrl: "https://static.nicegram.app/icon.png",
                        aboutUrl: "https://nicegram.app",
                        universalLink: "https://nicegram.app/tc",
                        deepLink: "nicegram-tc://",
                        jsBridgeKey: "nicegramWallet",
                        bridgeUrl: "https://bridge.tonapi.io/bridge",
                        platforms: ["ios", "android"]
                    },
                    {
                        appName: "fintopio-tg",
                        name: "Fintopio Telegram",
                        imageUrl: "https://fintopio.com/tonconnect-icon.png",
                        aboutUrl: "https://fintopio.com",
                        universalLink: "https://t.me/fintopio?attach=wallet",
                        bridgeUrl: "https://wallet-bridge.fintopio.com/bridge",
                        platforms: ["ios", "android", "macos", "windows", "linux"]
                    },
                    {
                        appName: "GateWallet",
                        name: "GateWallet",
                        imageUrl: "https://www.gate.io/images/login/qrcode_center_icon.svg",
                        aboutUrl: "https://www.gate.io/",
                        bridgeUrl: "https://dapp.gateio.services/tonbridge_api/bridge/v1",
                        jsBridgeKey: "gatetonwallet",
                        platforms: ["ios", "android", "chrome"],
                        universalLink: "https://gateio.onelink.me/DmA6/web3"
                    },
                    {
                        appName: "tokenpocket",
                        name: "TokenPocket",
                        imageUrl: "https://hk.tpstatic.net/logo/tokenpocket.png",
                        aboutUrl: "https://tokenpocket.pro",
                        jsBridgeKey: "tokenpocket",
                        platforms: ["ios", "android"]
                    },
                    {
                        appName: "dewallet",
                        name: "DeWallet",
                        imageUrl: "https://raw.githubusercontent.com/delab-team/manifests-images/main/WalletAvatar.png",
                        aboutUrl: "https://delabwallet.com",
                        universalLink: "https://t.me/dewallet?attach=wallet",
                        bridgeUrl: "https://bridge.dewallet.pro/bridge",
                        platforms: ["ios", "android", "macos", "windows", "linux"]
                    },
                    {
                        appName: "bybitTonWallet",
                        name: "Bybit Wallet",
                        imageUrl: "https://s1.bycsi.com/bybit/deadpool/image-ac5bf003d25c4ae0bd21f3725694a850.png",
                        aboutUrl: "https://www.bybit.com/web3",
                        universalLink: "https://app.bybit.com/ton-connect",
                        deepLink: "bybitapp://",
                        bridgeUrl: "https://api-node.bybit.com/spot/api/web3/bridge/ton/bridge",
                        jsBridgeKey: "bybitTonWallet",
                        platforms: ["ios", "android", "chrome"]
                    },
                    {
                        appName: "BitgetWeb3",
                        name: "BitgetWeb3",
                        imageUrl: "https://img.bitgetimg.com/image/third/1723701408284.png",
                        aboutUrl: "https://www.bitget.com",
                        universalLink: "https://t.me/BitgetOfficialBot?attach=wallet",
                        bridgeUrl: "https://ton-connect-bridge.bgwapi.io/bridge",
                        platforms: ["ios", "android", "windows", "macos", "linux"]
                    },
                    {
                        appName: "tonkeeper",
                        name: "Tonkeeper",
                        imageUrl: "https://tonkeeper.com/assets/tonconnect-icon.png",
                        aboutUrl: "https://tonkeeper.com",  // Страница о приложении
                        universalLink: "https://app.tonkeeper.com/ton-connect",  // Уникальная ссылка
                        bridgeUrl: "https://bridge.tonapi.io/bridge",  // Bridge URL
                        tondns: "tonkeeper.ton",
                        platforms: ["ios", "android", "chrome"]  // Поддерживаемые платформы
                    }
                ]
            }}
            actionsConfiguration={{
                twaReturnUrl: 'https://t.me/TonFermBot?startapp=command&mode=compact'
            }}
        >
            {children}
        </TonConnectUIProvider>
    );
}