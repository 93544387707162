import React from "react";
import './Nav.css'; // Импортируйте файл стилей

const Nav = ({ onNavClick, activeTab }) => {
    return (
        <div className="nav-bar">
            <button
                className={`market ${activeTab === 'market' ? 'active' : ''}`}
                onClick={() => onNavClick('market')}
            >
                Market
            </button>
            <button
                className={`farm ${activeTab === 'farm' ? 'active' : ''}`}
                onClick={() => onNavClick('farm')}
            >
                Farm
            </button>
            <button
                className={`friends ${activeTab === 'friends' ? 'active' : ''}`}
                onClick={() => onNavClick('friends')}
            >
                Friends
            </button>
            <button
                className={`rewards ${activeTab === 'rewards' ? 'active' : ''}`}
                onClick={() => onNavClick('rewards')}
            >
                Rewards
            </button>
        </div>
    );
}

export default Nav;