import React, {useState, useEffect} from "react";
import axios from "axios";
import {initUtils} from '@telegram-apps/sdk';
import Copy from "../../../assets/copy.png";
import "./Friends.css";
import {useAuth} from "../../../context/AuthContext";

const Friends = () => {
    const {authToken} = useAuth();
    const host = process.env.REACT_APP_HOST;
    const [link, setLink] = useState("");
    const [refs, setRefs] = useState([]);
    const [reward, setReward] = useState(0);

    useEffect(() => {
        const fetchLink = async () => {
            try {
                const response = await axios.get(`${host}/referral/link`, {
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                setLink(response.data);
            } catch (error) {
                console.error('Error fetching referral link:', error);
            }
        };

        const fetchRefs = async () => {
            try {
                const response = await axios.get(`${host}/referral/refs`, {
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                setRefs(response.data);
            } catch (error) {
                console.error('Error fetching referral references:', error);
            }
        };

        const fetchReward = async () => {
            try {
                const response = await axios.get(`${host}/referral/reward`, {
                    headers: {Authorization: `Bearer ${authToken}`}
                });
                setReward(response.data);
            } catch (error) {
            }
        };

        fetchRefs();
        fetchLink();
        fetchReward();
    }, [authToken, host]);

    const handleInviteFriend = () => {
        if (link) {
            const shareText = `Join us and start earning Ton coin right now! Don't miss your chance to be part of this exciting platform!`;
            const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(shareText)}`;

            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

            if (isIOS && window.navigator.share) {
                // Используем API navigator.share для iOS
                window.navigator.share({
                    title: 'Join us and start earning Ton coin right now!',
                    text: `${shareText} ${link}`,
                }).catch((error) => console.error('Error sharing:', error));
            } else {
                // Если не iOS, используем Telegram utils
                const utils = initUtils();
                utils.openTelegramLink(fullUrl);
            }
        } else {
            console.error("Link not available");
        }
    };

    const handleCopyLink = () => {
        if (link) {
            navigator.clipboard.writeText(link);
        } else {
            console.error("Link not available");
        }
    };

    const handleClaimReward = async () => {
        try {
            await axios.post(`${host}/referral/claim`, null, {
                headers: {Authorization: `Bearer ${authToken}`}
            });
            setReward(0);
        } catch (error) {
        }
    };

    return (
        <div className="friends-container">
            <h1 className="title">Invite friends</h1>
            <div className="rewards-section">
                <div className="reward-card">
                    <p>Invite a friend</p>
                    <p>🎁 +5% from a friend's shopping for you every month</p>
                </div>
            </div>

            <div className="reward-info">
                <p>💰 Your referral reward: {reward.toFixed(1)} TON</p>
                {reward > 0 && <button onClick={handleClaimReward} className="claim-button">Claim Reward</button>}
            </div>

            <div className="friends-list">
                {refs.map((friend, index) => (
                    <div key={index} className="friend">
                        <p> 🐹 {friend.username.length > 8 ? `${friend.username.slice(0, 8)}...` : friend.username}</p>
                        <p> Purchase: {friend.amount.toFixed(1)} </p>
                    </div>
                ))}
                {refs.length === 0 && (
                    <div className="no-friends">You haven't invited anyone yet.</div>
                )}
            </div>

            <div className="buttons-container">
                <button onClick={handleInviteFriend} className="invite">Invite Friend</button>
                <button onClick={handleCopyLink} className="copy">
                    <img src={Copy} alt="Copy Icon" className="copy-icon"/>
                </button>
            </div>
        </div>
    );
};

export default Friends;