import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import "./transactions.css";

const Transactions = () => {
    const { authToken } = useAuth();
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [filter, setFilter] = useState("WITHDRAWAL"); // По умолчанию выбран "WITHDRAWAL"
    const host = process.env.REACT_APP_HOST;

    const fetchTransactions = useCallback(async () => {
        try {
            const response = await axios.get(`${host}/transaction/all`, {
                headers: { Authorization: `Bearer ${authToken}` }
            });

            const fetchedTransactions = Array.isArray(response.data) ? response.data : [];
            setTransactions(fetchedTransactions); // Устанавливаем все транзакции

            // Применяем фильтрацию, сортировку по дате и ограничение до 40 транзакций для выбранного фильтра
            const filtered = fetchedTransactions
                .filter(transaction => transaction.type === filter)
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Сортировка по дате
                .slice(0, 20); // Ограничиваем до 40
            setFilteredTransactions(filtered);
        } catch (error) {
        }
    }, [authToken, host, filter]);

    useEffect(() => {
        fetchTransactions();
    }, [fetchTransactions]);

    useEffect(() => {
        // Обновляем фильтрованные транзакции при изменении фильтра с сортировкой
        const filtered = transactions
            .filter(transaction => transaction.type === filter)
            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Сортировка по дате
            .slice(0, 20);
        setFilteredTransactions(filtered);
    }, [filter, transactions]);

    const getStatusClass = (status) => {
        switch (status) {
            case "SUCCESS":
                return "success";
            case "REJECTED":
                return "reject";
            case "PENDING":
                return "pending";
            default:
                return "";
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        }).replace(",", ""); // Убираем запятую между датой и временем
    };

    return (
        <div>
            <div className="transactions-buttons">
                <button
                    onClick={() => setFilter("WITHDRAWAL")}
                    className={filter === "WITHDRAWAL" ? "active" : ""}
                >
                    PayOut
                </button>
                <button
                    onClick={() => setFilter("PURCHASE")}
                    className={filter === "PURCHASE" ? "active" : ""}
                >
                    Purchase
                </button>
            </div>
            <div className="transactions-scroll-container">
                <div className="transactions-container">
                    {filteredTransactions.map((transaction) => (
                        <div key={transaction.id} className={`transaction-item ${getStatusClass(transaction.status)}`}>
                            {transaction.type !== 'WITHDRAWAL' && <p>{transaction.type}</p>}
                            {transaction.type === 'WITHDRAWAL' &&  <p>{transaction.status}</p>}
                            <p>Amount: {transaction.amount}</p>
                            <p>Date: {formatDate(transaction.date)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Transactions;