import React, {useState} from 'react';
import {TonConnectProvider} from '../components/TonConnectProvider';
import {Header} from '../components/Header';
import {TonProvider} from "../context/TonContext";
import "./home.css"
import HomeBody from "../components/HomeBody";
import UserEnergy from "../components/nav_pages/energy/UserEnergy";
import QR from "../assets/qr.jpg";

const Home = () => {

    const [energy, setEnergy] = useState(0);
    const telegram = window.Telegram?.WebApp;

    const handleEnergyUpdate = (newEnergy) => {
        setEnergy(newEnergy);
    };

    // Проверка на платформу
    if (!['android', 'android_x', 'ios'].includes(telegram?.platform)) {
        return (
            <div className="content-no-telegram">
                <p>Mining on your mobile</p>
                <a href="https://t.me/TonFermBot" rel="noopener noreferrer">
                    <img src={QR} alt="QR Code" />
                </a>
                <a href="https://t.me/TonFermBot" rel="noopener noreferrer">
                    @TonFermBot
                </a>
            </div>
        );
    }

    return (
        <div className="container">
            <TonConnectProvider>
                <TonProvider>
                    <UserEnergy onEnergyUpdate={handleEnergyUpdate}/>
                    <Header/>
                    <HomeBody energy={energy}/>
                </TonProvider>
            </TonConnectProvider>
        </div>
    );
};

export default Home;