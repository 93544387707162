import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Auth = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const host = process.env.REACT_APP_HOST

    useEffect(() => {
        const telegram = window.Telegram?.WebApp;
        if (telegram) {
            const initData = telegram.initData;
            const sendDataToBackend = async (initData) => {
                try {
                    const response = await axios.post( `${host}/api/auth`, initData, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });

                    const data = response.data;

                    if (response.status === 200) {
                        login(data.authToken);
                        navigate('/home', { state: { authToken: data.authToken } });
                    } else {
                        console.error('Unexpected response status:', response.status);
                    }
                } catch (error) {
                    navigate('/error');
                }
            };
            sendDataToBackend(initData).then();
        }
    }, [navigate]);

    return null;
};

export default Auth;