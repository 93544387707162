import Elite from "../assets/elite.png"
import ElitePlus from "../assets/elite_plus.png"
import Energy from "../assets/energy.png"
import GlobalPro from "../assets/globalpro.png"
import GlobalProMax from "../assets/global_pro_max.png"
import Pro from "../assets/pro.png"
import Pro2 from "../assets/pro2.png"
import Start from "../assets/start.png"

export function Cards() {
    return[
        {id:1,img:Energy, title: "Energy Booster", description: "Keep your miners running at full capacity with additional energy packs",price:0.5
            ,energyConsumption:"50 tot",production:"none"},
        {id:2,img:Start, title: "Starter Miner", description: "The perfect start for new miners. Low cost — maximum efficiency",price:3
            ,energyConsumption:"100 mo",production:"1.2 ton/mo"},
        {id:3,img:Pro, title: "Pro Miner", description: "Boost your mining power with a stable and efficient solution",price:7.5
            ,energyConsumption:"250 mo",production:"3 ton/mo"},
        {id:4,img:Pro2, title: "Pro Miner Plus", description: "Extra power and performance for those who want more",price:13
            ,energyConsumption:"400 mo",production:"5.2 ton/mo"},
        {id:5,img:GlobalPro, title: "Global Miner", description: "Global performance for professional-level mining",price:20
            ,energyConsumption:"650 mo",production:"8 ton/mo"},
        {id:6,img:GlobalProMax, title: "Global Miner Max", description: "Maximum output with minimal effort. Power at your fingertips",price:45
            ,energyConsumption:"1500 mo",production:"18 ton/mo"},
        {id:7,img:Elite, title: "Elite Miner", description: "An elite solution for those aiming for serious mining results",price:70
            ,energyConsumption:"2500 mo",production:"28 ton/mo"},
        {id:8,img:ElitePlus, title: "Elite Miner Plus", description: "For professionals seeking the highest returns. Your next level of mining",price:150
            ,energyConsumption:"5500 mo",production:"60 ton/mo"},
    ]
}

export function CardImg() {
    return  {
        "Starter Miner":Start,
        "Pro Miner": Pro,
        "Pro Miner Plus":Pro2,
        "Global Miner":GlobalPro,
        "Global Miner Max":GlobalProMax,
        "Elite Miner":Elite,
        "Elite Miner Plus":ElitePlus
    }
}