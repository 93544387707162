import React from 'react';
import { TonConnectButton } from "@tonconnect/ui-react";
import './header.css';

export const Header = () => {
    return (
        <div className={"header-container"}>
            {/* eslint-disable-next-line react/style-prop-object */}
            <TonConnectButton style={""}/>
        </div>
    );
};