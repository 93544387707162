import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import { useSpring, animated } from "@react-spring/web";
import { useAuth } from "../../../context/AuthContext";
import "./Farm.css";
import FarmCard from "./FarmCard";
import { CardImg } from "../../../data/MarketData";

const Farm = ({ activeTab, energy }) => {
    const { authToken } = useAuth();
    const img = CardImg();
    const [cards, setCards] = useState([]);
    const host = process.env.REACT_APP_HOST

    const [props, api] = useSpring(() => ({
        transform: "translateY(0px)",
        config: { tension: 170, friction: 26 },
    }));

    const handleScroll = (event) => {
        const scrollTop = event.target.scrollTop;
        api.start({ transform: `translateY(-${scrollTop}px)` });
    };


    const fetchCards = useCallback(async () => {
        try {
            const response = await axios.get(`${host}/card/all`, {
                headers:{ Authorization: `Bearer ${authToken}`}
            });
            const fetchedCards = response.data;
            setCards(fetchedCards);
        } catch (error) {
        }
    }, [authToken]);

    useEffect(() => {
        fetchCards();
    }, [fetchCards]);

    const handleCardStatusChange = useCallback(() => {
        fetchCards().then();
    }, [fetchCards]);

    return (
        <div className="market-container" onScroll={handleScroll}>
            {activeTab === "active" ? (
                <animated.div className="market-content" style={props}>
                    {cards
                        .filter(card => card.isActive)
                        .map(card => (
                            <FarmCard
                                key={card.id}
                                img={img[card.title]}
                                cardId={card.id}
                                authToken={authToken}
                                userEnergy={energy}
                                isActive={card.isActive}
                                title={card.title}
                                cardEnergy={card.enrgy}
                                onCardStatusChange={handleCardStatusChange}
                            />
                        ))}
                </animated.div>
            ) : (
                <animated.div className="market-content" style={props}>
                    {cards
                        .filter(card => !card.isActive)
                        .map(card => (
                            <FarmCard
                                key={card.id}
                                img={img[card.title]}
                                cardId={card.id}
                                authToken={authToken}
                                userEnergy={energy}
                                isActive={card.isActive}
                                title={card.title}
                                cardEnergy={card.energyConsumption}
                                onCardStatusChange={handleCardStatusChange}
                            />
                        ))}
                </animated.div>
            )}
        </div>
    );
};

export default Farm;