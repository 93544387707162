import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home"
import Auth from "./pages/Auth"
import {AuthProvider} from "./context/AuthContext";
import ProtectedRoute from "./context/ProtectedRoute";


function App() {
    return (
        <AuthProvider>
            <Rout/>
        </AuthProvider>
    )
}

const Rout = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route path={"/home"} element={<Home/>}/>*/}
                <Route path="/" element={<Auth/>}/>
                <Route path="/home" element={<ProtectedRoute element={Home}/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App