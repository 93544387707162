import React, {useState} from "react";
import {useSpring, animated} from "@react-spring/web";
import "./Card.css";

const Card = ({img, title, description, price, onQuantityChange, energyConsuption, production}) => {
    const [flipped, setFlipped] = useState(false);
    const [quantity, setQuantity] = useState(0);

    // Define spring animation
    const {transform} = useSpring({
        transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
        config: {tension: 1000, friction: 60}
    });

    const handleClick = () => {
        setFlipped(prev => !prev);
    };

    const increment = (e) => {
        e.stopPropagation();
        setQuantity(prev => {
            const newQuantity = prev + 1;
            onQuantityChange(title, price, newQuantity); // Notify parent component
            return newQuantity;
        });
    };

    const decrement = (e) => {
        e.stopPropagation();
        setQuantity(prev => {
            const newQuantity = Math.max(prev - 1, 0);
            onQuantityChange(title, price, newQuantity); // Notify parent component
            return newQuantity;
        });
    };

    return (
        <div className="card-container" onClick={handleClick}>
            <animated.div className="card" style={{transform}}>
                <div className="card-front" style={{backgroundImage: `url(${img})`}}>
                    <div className="card-content">
                        <h3 className="card-title">{title}</h3>
                        <p className="card-description">{description}</p>
                        <p>{price + ' Ton'}</p>
                        <div className="card-quantity">
                            <button className="quantity-button" onClick={decrement}>-</button>
                            <span className="quantity-value">{quantity}</span>
                            <button className="quantity-button" onClick={increment}>+</button>
                        </div>
                        <button className="info-button" onClick={(e) => {
                            e.stopPropagation();
                            handleClick();
                        }}>i</button>
                    </div>
                </div>
                <div className="card-back">
                    <div className={"card-back-content"}>
                        <div className={"energy-content"}>
                            <p className={"energy"}>{'Energy: ' + energyConsuption}</p>
                        </div>
                        {title !== 'Energy Booster' &&
                            <div className={"energy-content"}>
                                <p className={"energy"}>{`Durability: ${6} mo`}</p>
                            </div>
                        }
                        {title !== 'Energy Booster' &&
                            <div className={"energy-content"}>
                                <p className={"reward"}>{'Reward: ' + production}</p>
                            </div>
                        }

                    </div>
                </div>
            </animated.div>
        </div>
    );
};

export default Card;