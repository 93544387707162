import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import Play from "../../../assets/play.png"
import Stop from "../../../assets/stop-button.png"
import "./FarmCard.css"

const FarmCard = ({img, cardId, authToken, userEnergy, isActive, title, cardEnergy, onCardStatusChange, totalEnergyConsumption}) => {
    const [isRunning, setIsRunning] = useState(isActive);
    const host = process.env.REACT_APP_HOST

    const startCard = async () => {
        if (!isRunning ) {
            try {
                const response = await axios.post(`${host}/card/start`, {id: cardId}, {
                    headers:{ Authorization: `Bearer ${authToken}`}
                });
                if (response.status === 200) {
                    setIsRunning(true);
                    onCardStatusChange();
                }
            } catch (error) {
            }
        }
    };

    const stopCard = useCallback(async () => {
        if (isRunning) {
            try {
                const response = await axios.post( `${host}/card/stop`, {id: cardId}, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                });
                if (response.status === 200) {
                    setIsRunning(false);
                    onCardStatusChange();
                }
            } catch (error) {
            }
        }
    }, [isRunning, authToken, cardId]);


    const remainingEnergyPercentage = cardEnergy > 0
        ? ((userEnergy / cardEnergy) * 100) : 0;

    return (
        <div className="farm-card-container">
            <div
                className={`farm-card-front ${isRunning ? 'active' : ''}`}
                style={{backgroundImage: `url(${img})`}}
            >
                <div className={"farm-card-title"}>{title}</div>
                <button
                    className="start"
                    onClick={startCard}
                    disabled={isRunning && userEnergy >= 0}
                >
                    <img src={Play} alt="Play"/>
                </button>
                <button
                    className="stop"
                    onClick={stopCard}
                    disabled={!isRunning}
                >
                    <img src={Stop} alt="Stop"/>
                </button>
            </div>
            {isRunning &&
                <div className="energy-bar">
                    <div
                        className="energy-bar-inner"
                        style={{width: `${remainingEnergyPercentage}%`}}
                    />
                </div>
            }
        </div>
    );
};

export default FarmCard;